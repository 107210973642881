import React, { useContext, useEffect, useMemo, useState } from "react";
import {
  Button,
  DatePicker,
  Label,
  Select,
  Textarea,
} from "@src/common/components";
import { Controller, useFieldArray, useFormContext } from "react-hook-form";
import ControllerInput from "./ControllerInput";
import SelectPackage from "@src/orders/create/components/SelectPackage";
import CostCenterSelector from "./CostCenterSelector";
import ServiceTypeSelector from "@src/orders/create/components/ServiceTypeSelector";
import { useShipmentSelectionContext } from "@src/shipments/context/ShipmentSelectionContext";
import EndCustomerSearchInput from "@src/endcustomers/components/EndCustomerSearchInput";
import {
  CustomFieldType,
  EndCustomer,
  ServiceType,
  ServiceTypeDueTimeType,
  ShipmentStopType,
  ShipmentType,
  Stop,
  StopCreateInput,
} from "@api/graphql/generated/generated-types";
import { getOptionLabelForShipmentType } from "@src/common/lib/textUtils";
import { ErrorMessage } from "@hookform/error-message";
import StopForm from "./StopForm";
import AddStopButton from "@src/orders/create/components/AddStopButton";
import { XCircleIcon } from "@heroicons/react/24/outline";
import {
  returnObjectForSelectValue,
  shipmentTypeArray,
} from "@src/common/lib/EditShipmentSelectOptionsUtils";
import DurationTypeSelector from "@src/orders/create/components/GeneralForm/components/DurationTypeSelector";
import moment from "moment";
import { AuthContext } from "@src/auth/components/AuthProvider";
import { findLastIndex } from "lodash";
import "moment-business-days"; // Import moment-business-days to extend moment
import FormInput from "@src/orders/create/components/FormInput";

type Props = {
  stopIdsToDelete: string[];
  setStopIdsToDelete: (stopIdsToDelete: string[]) => void;
  setIsAllStopsHaveAddress: (isAllStopsHaveAddress: boolean) => void;
  selectedDuration: string;
  setSelectedDuration: React.Dispatch<React.SetStateAction<string>>;
  onStopDateChange: () => void;
};

function EditOrderForm({
  stopIdsToDelete,
  setStopIdsToDelete,
  setIsAllStopsHaveAddress,
  selectedDuration,
  setSelectedDuration,
  onStopDateChange,
}: Props) {
  const { courier } = useContext(AuthContext);
  const timeZoneId = courier?.courier?.timeZoneId;
  const { shipmentSelectedForAction } = useShipmentSelectionContext();
  const [selectedCustomer, setSelectedCustomer] =
    useState<EndCustomer | undefined>(undefined);
  const {
    control,
    setValue,
    formState: { errors },
    watch,
    setError,
    clearErrors,
    getValues,
  } = useFormContext();
  const { append, insert, remove } = useFieldArray({
    name: "stops",
    control: control,
  });

  const courierPackageTypes = courier?.courier?.packageTypes;
  const packageTypes =
    courierPackageTypes && courierPackageTypes.length > 0
      ? courier?.courier?.packageTypes
      : undefined;
  const shipmentDate = watch("shipmentDate");
  const memoizedShipmentDate = useMemo(() => shipmentDate, [shipmentDate]);

  useEffect(() => {
    const selectedEndCustomer = shipmentSelectedForAction?.order.endCustomer;
    if (selectedEndCustomer) {
      setSelectedCustomer(selectedEndCustomer);
    }
  }, [shipmentSelectedForAction]);

  useEffect(() => {
    if (selectedCustomer) {
      setValue("endCustomerId", selectedCustomer.id);
      clearErrors("endCustomerId");
    } else {
      setValue("endCustomerId", undefined);
      setError("endCustomerId", {
        type: "manual",
        message: "Please select a customer for this order",
      });
    }
  }, [selectedCustomer]);

  const watchPackageTypeId = watch("packageTypeId");
  const serviceTypeId = watch("serviceTypeId");
  const watchStops = watch("stops");
  const shipmentType = watch(`type`);
  const stopsLength = watch("stops")?.length;
  const { pickUpCount, dropOffCount } = watchStops
    ? watchStops.reduce(
        (acc, stop) => {
          if (stop.type === ShipmentStopType.PickUp) {
            acc.pickUpCount += 1;
          } else if (stop.type === ShipmentStopType.DropOff) {
            acc.dropOffCount += 1;
          }
          return acc;
        },
        { pickUpCount: 0, dropOffCount: 0 }
      )
    : { pickUpCount: 0, dropOffCount: 0 };
  const customFields = courier?.courier?.customFields;

  useEffect(() => {
    const allStopsHaveAddress =
      watchStops?.every((stop) => stop.streetAddress) ?? false;
    setIsAllStopsHaveAddress(allStopsHaveAddress);
  }, [JSON.stringify(watchStops), setIsAllStopsHaveAddress]);

  const changeStopsTypes = (shipmentType: string) => {
    const isMultiDropOff =
      shipmentType === ShipmentType.MultiDropOff ||
      shipmentType === ShipmentType.MultiDropOffRoundTrip;
    if (isMultiDropOff && watchStops.length > 1) {
      setValue(
        "stops",
        watchStops.map((stop, index) => ({
          ...stop,
          type:
            index === 0 ? ShipmentStopType.PickUp : ShipmentStopType.DropOff,
        }))
      );
    } else if (
      shipmentType === ShipmentType.MultiPickUp &&
      watchStops.length > 1
    ) {
      setValue(
        "stops",
        watchStops.map((stop, index) => ({
          ...stop,
          type:
            index === watchStops.length - 1
              ? ShipmentStopType.DropOff
              : ShipmentStopType.PickUp,
        }))
      );
    }
  };

  function renderRemoveStopButton(stopType, index, stop: Stop) {
    if (dropOffCount === 1 && stopType === ShipmentStopType.DropOff) {
      return null;
    }
    if (pickUpCount === 1 && stopType === ShipmentStopType.PickUp) {
      return null;
    }
    if (stopsLength > 2) {
      return (
        <Button
          color={"secondary"}
          className="border truncate hover:bg-gray-100 dark:hover:bg-gray-800"
          size={"xs"}
          onClick={() => {
            if (stop.id) {
              setStopIdsToDelete([...stopIdsToDelete, stop.id]);
            }
            remove(index);
          }}
        >
          <XCircleIcon className="h-5 w-5 mr-2" />
          Remove Stop
        </Button>
      );
    }
  }

  const updateStopDates = () => {
    if (selectedDuration === "noStopDates") {
      return;
    }
    watchStops.forEach((stop, index) => {
      const stopDate = stop.stopDate;
      const stopType = stop.type;
      if (!stopDate) {
        if (stopType === ShipmentStopType.PickUp) {
          setValue(`stops.${index}.stopDate`, memoizedShipmentDate);
          const path = `stops.${index}.timeWindow.${"open"}`;
          const readyByTime = getValues(path);
          if (!readyByTime) {
            setValue(path, moment(memoizedShipmentDate).toDate());
          }
        } else {
          const nextDay = moment(memoizedShipmentDate).add(1, "days");
          setValue(`stops.${index}.stopDate`, nextDay.toDate());
          const path = `stops.${index}.timeWindow.${"close"}`;
          const deliverByTime = getValues(path);
          if (!deliverByTime) {
            setValue(path, moment(nextDay).add(2, "hours").toDate());
          }
        }
      }
    });
  };

  const getPackageTypeSelectValue = (value: string) => {
    if (!value || !packageTypes) return undefined;
    const selectedPackageType = packageTypes.find((type) => type.id === value);
    return [
      {
        id: selectedPackageType?.id,
        label: selectedPackageType?.name,
      },
    ];
  };

  const handleChangeServiceType = (
    selectedServiceType: ServiceType | undefined
  ) => {
    if (selectedServiceType) {
      setValue("serviceTypeId", selectedServiceType.id);

      if (
        selectedServiceType.dueTimeType === ServiceTypeDueTimeType.Fixed &&
        timeZoneId
      ) {
        const dueTimeInBusinessDaysAfter =
          selectedServiceType.dueTimeInBusinessDaysAfter as number;
        const dueTimeSecondsSinceMidnight =
          selectedServiceType.dueTimeSecondsSinceMidnight;

        const dueTimeInCourierTimeZone = moment
          .tz(timeZoneId)
          .startOf("day")
          .businessAdd(dueTimeInBusinessDaysAfter)
          .add(dueTimeSecondsSinceMidnight, "seconds");

        const stopIndex = findLastIndex(watchStops, (field: any) => {
          return field.type === ShipmentStopType.DropOff;
        });
        if (stopIndex >= 0) {
          setValue(
            `stops.${stopIndex}.timeWindow.close`,
            dueTimeInCourierTimeZone.unix().toString()
          );
        }
        if (dueTimeInBusinessDaysAfter > 0) {
          setSelectedDuration("hasStopDates");
          setValue(
            `stops.${stopIndex}.stopDate`,
            dueTimeInCourierTimeZone.toDate()
          );
        }
        if (dueTimeInBusinessDaysAfter === 0) {
          setSelectedDuration("noStopDates");
          setValue(`stops.${stopIndex}.stopDate`, null);
        }
      }
      if (
        selectedServiceType.deliveryTimeInMinutes &&
        selectedServiceType.dueTimeType ===
          ServiceTypeDueTimeType.RelativeToPickup
      ) {
        const pickUpStop = watchStops.find(
          (stop: StopCreateInput) => stop.type === ShipmentStopType.PickUp
        );
        const pickUpStopReadyTime = pickUpStop?.timeWindow?.open;
        // Convert pickUpStopReadyTime Unix timestamp to Date
        const pickUpStopReadyDate = pickUpStopReadyTime
          ? new Date(parseInt(pickUpStopReadyTime) * 1000)
          : undefined;
        const dueTime = calculateDueTime(
          selectedServiceType.deliveryTimeInMinutes,
          pickUpStopReadyDate?.toISOString()
        );
        const stopIndex = findLastIndex(watchStops, (field: any) => {
          return field.type === ShipmentStopType.DropOff;
        });

        if (stopIndex >= 0) {
          setValue(
            `stops.${stopIndex}.timeWindow.close`,
            moment(dueTime).unix()
          );
          setSelectedDuration("noStopDates");
        }
      }
    } else {
      setValue("serviceTypeId", undefined);
    }
  };

  // Function to calculate due time
  const calculateDueTime = (
    deliveryTimeInMinutes: number,
    pickUpStopReadyTime?: string | undefined
  ) => {
    if (pickUpStopReadyTime) {
      const pickUpStopReadyTimeInDate = new Date(pickUpStopReadyTime);
      pickUpStopReadyTimeInDate.setMinutes(
        pickUpStopReadyTimeInDate.getMinutes() + deliveryTimeInMinutes
      );
      return pickUpStopReadyTimeInDate;
    }
    const currentTime = new Date();
    currentTime.setMinutes(currentTime.getMinutes() + deliveryTimeInMinutes);
    return currentTime;
  };

  return (
    <div className="grid grid-cols-3 gap-2 mb-20">
      <div className="col-span-2 flex justify-between gap-2">
        <div className="flex flex-col flex-1">
          <Label className="block text-xs font-medium text-gray-700 truncate">
            Customer{" "}
            {errors.endCustomerId && (
              <span className=" text-red-500">
                (Please select a customer for this order)
              </span>
            )}
          </Label>
          <EndCustomerSearchInput
            selectedCustomer={selectedCustomer}
            setSelectedCustomer={setSelectedCustomer}
            size="mini"
          />
        </div>
        <FormInput
          name="caller"
          label="Caller"
          control={control}
          errors={errors}
        />
      </div>
      <CostCenterSelector
        control={control}
        setValue={setValue}
        order={shipmentSelectedForAction?.order}
      />
      <div className="col-span-3">
        <Controller
          control={control}
          name={"shipmentDate"}
          render={({ field }) => (
            <div>
              <Label className="block text-xs font-medium text-gray-700 truncate">
                Shipment Date
              </Label>
              <DatePicker
                size="mini"
                value={field.value}
                formatString={"MMM do, yyyy"}
                onChange={({ date }) => field.onChange(date)}
                autoFocusCalendar={false}
              />
            </div>
          )}
        />
      </div>
      <ol className="relative ml-8 mt-1 col-span-3 text-gray-500 border-l border-primary-700 border-dashed dark:border-gray-700 dark:text-gray-400">
        {watchStops &&
          watchStops.length > 0 &&
          watchStops.map((stop, index) => {
            const value = stop.streetAddress;
            const type = stop.type;
            return (
              <>
                <StopForm
                  key={index}
                  index={index}
                  errors={errors}
                  value={value}
                  selectedCustomer={selectedCustomer}
                  getValues={getValues}
                  clearErrors={clearErrors}
                  setError={setError}
                  setValue={setValue}
                  control={control}
                  selectedDuration={selectedDuration}
                  onStopDateChange={onStopDateChange}
                />
                <div className="ml-8 -mt-4 mb-8">
                  {renderRemoveStopButton(type, index, stop)}
                </div>
              </>
            );
          })}
      </ol>
      <div className="col-span-3 px-4 mb-4 -mt-3">
        <AddStopButton
          append={append}
          insert={insert}
          shipmentType={shipmentType}
          watchStops={watchStops}
          onAddStop={() => updateStopDates()}
        />
      </div>
      <div className="col-span-3 grid grid-cols-4 gap-4">
        <div className="col-span-1">
          {shipmentSelectedForAction && (
            <>
              <Label className="block text-xs font-medium text-gray-700 truncate">
                Service Type
              </Label>
              <ServiceTypeSelector
                control={control}
                errors={errors}
                size="mini"
                value={serviceTypeId}
                onChange={(value) => {
                  handleChangeServiceType(value as ServiceType | undefined);
                }}
              />
            </>
          )}
        </div>
        <div className="flex flex-col">
          <DurationTypeSelector
            setSelectedDuration={setSelectedDuration}
            selectedDuration={selectedDuration}
            onChange={() => updateStopDates()}
          />
        </div>
        <div className="col-span-1">
          <Label
            htmlFor="serviceTypeId"
            className="block text-xs font-medium text-gray-700"
          >
            Stops
            <span className="text-red-500 ml-3">
              <ErrorMessage errors={errors} name="type" />
            </span>
          </Label>
          <Controller
            name={`type`}
            control={control}
            rules={{ required: false }}
            render={({ field: { onChange } }) => (
              <Select
                size="mini"
                options={shipmentTypeArray}
                value={returnObjectForSelectValue(getValues)}
                placeholder=""
                searchable={false}
                clearable={false}
                error={errors?.shipments?.[0]?.type?.message}
                getValueLabel={({ option }) =>
                  typeof option.label === "string"
                    ? getOptionLabelForShipmentType(option.label)
                    : ""
                }
                getOptionLabel={({ option }) =>
                  typeof option.label === "string"
                    ? getOptionLabelForShipmentType(option.label)
                    : "test"
                }
                onChange={(params) => {
                  if (
                    params.value[0].label === ShipmentType.MultiDropOff ||
                    params.value[0].label ===
                      ShipmentType.MultiDropOffRoundTrip ||
                    params.value[0].label === ShipmentType.MultiPickUp
                  ) {
                    changeStopsTypes(params.value[0].label as string);
                  }
                  onChange(params.value[0].label);
                }}
              />
            )}
          />
        </div>
        <div className="col-span-1">
          <ControllerInput
            name={"endCustomerReferenceNumber"}
            label={"Customer Reference #"}
            className={"col-span-1"}
          />
        </div>
      </div>
      <ControllerInput
        name={"packageWeightInPounds"}
        label={"Package Weight (lbs)"}
        className={"col-span-1"}
      />
      <ControllerInput
        name={"numPackages"}
        label={"Package Count"}
        className={"col-span-1"}
      />
      {packageTypes ? (
        <div className="col-span-1 flex flex-col">
          <Label className="text-xs">
            Package Type{" "}
            <span className="text-slate-400 text-xs">(Optional)</span>
          </Label>
          <Controller
            name={`packageTypeId`}
            control={control}
            render={({ field: { onChange, value } }) => {
              return (
                <Select
                  size="mini"
                  backspaceRemoves={false}
                  clearable={true}
                  deleteRemoves={false}
                  escapeClearsValue={false}
                  options={packageTypes.map((type) => ({
                    id: type.id,
                    label: type.name,
                  }))}
                  value={getPackageTypeSelectValue(
                    watchPackageTypeId as string
                  )}
                  searchable={false}
                  onChange={(params) => {
                    const value =
                      params.value.length === 0
                        ? undefined
                        : params.value[0].id;
                    const selectedPackageTypeId = packageTypes.find(
                      (pkg) => pkg.id === value
                    )?.id;
                    onChange(selectedPackageTypeId);
                  }}
                />
              );
            }}
          />
        </div>
      ) : (
        <SelectPackage
          control={control}
          name={"packageSize"}
          size="mini"
          label={<Label className="text-xs">Package Size </Label>}
        />
      )}
      <div className="col-span-3">
        <Label className="text-xs">
          Package Description{" "}
          <span className="text-slate-400 text-xs">(Optional)</span>
        </Label>
        <Controller
          name={`packageDescription`}
          control={control}
          render={({ field: { onChange, value } }) => {
            return (
              <Textarea
                size="mini"
                placeholder="Enter Package Description"
                onChange={onChange}
                value={value}
              />
            );
          }}
        />
      </div>
      {customFields &&
        customFields?.map((field) => {
          if (field.type === CustomFieldType.Text) {
            return (
              <ControllerInput
                key={field.id}
                name={`customFieldValuesToReplace.${field.id}`}
                label={field.name}
                className={"col-span-1"}
              />
            );
          }
          return null;
        })}
    </div>
  );
}

export default EditOrderForm;
