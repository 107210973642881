import React, { useContext, useEffect, useState } from "react";
import { Checkbox, RangeSlider } from "flowbite-react";
import {
  Driver,
  DriverEdge,
  GetDriverDefaultRoutingConfigurationsQuery,
  TaskStatus,
  useFetchDriverTasksByDateAndStatusLazyQuery,
} from "@api/graphql/generated/generated-types";
import { AuthContext } from "@src/auth/components/AuthProvider";
import { DRIVER_API_LIMIT } from "@src/common/constants/apiConstants";
import { sortDriversByName } from "@src/common/lib/driverSorters";
import { makeDriverOptions } from "@src/shipments/utils/makeDriverOptions";
import { DriverOption } from "../DriverFilterSelector";
import { Loading } from "@src/common/components";
import { DriverNameCell } from "@src/common/components/DriverNameCell/DriverNameCell";
import { SelectedDriversByDate } from "./CreateRoutingJobModal";
import { useCourieStore } from "@src/common/lib/store";
import { showErrorToast } from "@src/common/lib/NetworkErrorHandling";

type Props = {
  selectedDriversByDate: SelectedDriversByDate;
  setSelectedDriversByDate: React.Dispatch<
    React.SetStateAction<SelectedDriversByDate>
  >;
  driverSpeeds: { [key: string]: number };
  setDriverSpeeds: (speeds: { [key: string]: number }) => void;
  selectedStopDate: string;
  getDriverDefaultRoutingConfigurations: any;
  driverDefaultRoutingConfigurationsLoading: boolean;
  driverDefaultRoutingConfigurationsQuery:
    | GetDriverDefaultRoutingConfigurationsQuery
    | undefined;
};

function SelectDriversCreateRoutingJobSlide({
  selectedDriversByDate,
  setSelectedDriversByDate,
  driverSpeeds,
  setDriverSpeeds,
  selectedStopDate,
  getDriverDefaultRoutingConfigurations,
  driverDefaultRoutingConfigurationsLoading,
  driverDefaultRoutingConfigurationsQuery,
}: Props) {
  const { courierId } = useContext(AuthContext);
  const { showToast } = useCourieStore();
  const [drivers, setDrivers] = useState<DriverOption[]>([]);
  const [
    FetchDriverTasksByDateAndStatus,
    {
      loading: FetchDriverTasksByDateAndStatusLoading,
      error: FetchDriverTasksByDateAndStatusError,
    },
  ] = useFetchDriverTasksByDateAndStatusLazyQuery({
    fetchPolicy: "network-only",
  });

  useEffect(() => {
    if (drivers.length === 0) return;
    getDriverDefaultRoutingConfigurations({
      variables: {
        driverIds: drivers.map((driver) => driver.id),
        dates: selectedStopDate,
      },
    }).catch((err) => {
      showErrorToast(err, showToast);
    });
  }, [selectedStopDate, drivers]);

  useEffect(() => {
    if (!courierId || !selectedStopDate) {
      return;
    }
    FetchDriverTasksByDateAndStatus({
      variables: {
        courierId,
        date: selectedStopDate,
        statusFilter: [TaskStatus.Created, TaskStatus.Started],
        limit: DRIVER_API_LIMIT,
      },
    }).then((res) => {
      const fetchedDrivers = makeDriverOptions(
        (res.data?.drivers?.edges as DriverEdge[]) || []
      );
      const sortedDrivers = sortDriversByName(fetchedDrivers);
      setDrivers(sortedDrivers);
    });
  }, [courierId, selectedStopDate]);

  const handleDriverSelection = (driver: DriverOption) => {
    if (!selectedStopDate) return;

    const currentSelectedDrivers =
      selectedDriversByDate[selectedStopDate] || [];

    if (currentSelectedDrivers.some((d) => d.id === driver.id)) {
      // If the driver is already selected, remove them from the list
      const updatedDrivers = currentSelectedDrivers.filter(
        (d) => d.id !== driver.id
      );

      // Update state, removing the date key if no drivers are selected
      if (updatedDrivers.length === 0) {
        const { [selectedStopDate]: _, ...restDates } = selectedDriversByDate;
        setSelectedDriversByDate(restDates);
      } else {
        setSelectedDriversByDate({
          ...selectedDriversByDate,
          [selectedStopDate]: updatedDrivers,
        });
      }

      // Remove driver speed
      const { [driver.id]: _, ...rest } = driverSpeeds;
      setDriverSpeeds(rest);
    } else {
      // If the driver is not selected, add them to the list
      const updatedDrivers = [...currentSelectedDrivers, driver];

      setSelectedDriversByDate({
        ...selectedDriversByDate,
        [selectedStopDate]: updatedDrivers,
      });

      handleDriverSpeedChange(driver.id, driver.driver?.speed || 1.0);
    }
  };

  const handleDriverSpeedChange = (driverId: string, speed: number) => {
    setDriverSpeeds({
      ...driverSpeeds,
      [driverId]: speed,
    });
  };

  const handleSelectAllDrivers = (checked: boolean) => {
    if (checked) {
      const allDrivers = drivers.map(driver => ({
        ...driver,
        speed: driver.driver?.speed || 1.0
      }));
      setSelectedDriversByDate({
        ...selectedDriversByDate,
        [selectedStopDate]: allDrivers,
      });
      const newDriverSpeeds = drivers.reduce((acc, driver) => {
        acc[driver.id] = driver.driver?.speed || 1.0;
        return acc;
      }, {} as { [key: string]: number });
      setDriverSpeeds(newDriverSpeeds);
    } else {
      // Remove the date key entirely instead of setting it to an empty array
      const { [selectedStopDate]: _, ...restDates } = selectedDriversByDate;
      setSelectedDriversByDate(restDates);
      setDriverSpeeds({});
    }
  };

  const areAllDriversSelected =
    drivers.length > 0 &&
    selectedDriversByDate[selectedStopDate]?.length === drivers.length;

  const renderIsOffToday = (driver: DriverOption) => {
    if (driverDefaultRoutingConfigurationsQuery) {
      const driverDefaultRoutingConfiguration =
        driverDefaultRoutingConfigurationsQuery.driverDefaultRoutingConfigurations.find(
          (config) => config.driverId === driver.id
        );
      if (!driverDefaultRoutingConfiguration) {
        return null;
      }
      if (driverDefaultRoutingConfiguration.isWorkDay) {
        return null;
      }
      return (
        <div className="text-yellow-400 font-semibold text-xs text-center">
          Out of Schedule
        </div>
      );
    }
  };

  return (
    <Loading
      loading={
        FetchDriverTasksByDateAndStatusLoading ||
        driverDefaultRoutingConfigurationsLoading
      }
      text="Loading drivers"
      className="overflow-auto flex flex-col gap-3 max-h-[calc(100vh-300px)] p-4 min-w-[600px]"
    >
      {/* Table Headers */}
      <div className="flex items-center gap-4 p-2 rounded-md bg-gray-100 shadow-sm">
        <Checkbox
          checked={areAllDriversSelected}
          onChange={(e) => handleSelectAllDrivers(e.target.checked)}
          className="form-checkbox h-5 w-5 cursor-pointer"
        />
        <span className="w-1/2 text-sm font-medium text-gray-600 text-left">
          Driver
        </span>
        <span className="w-1/4 text-sm font-medium text-gray-600 text-center">
          Speed
        </span>
      </div>
      {drivers.map((driver) => (
        <div
          key={driver.id}
          className="flex items-center gap-4 p-2 rounded-md bg-white hover:shadow-lg shadow-sm transition-all"
        >
          <Checkbox
            checked={
              selectedDriversByDate[selectedStopDate]?.some(
                (d) => d.id === driver.id
              ) || false
            }
            className="form-checkbox h-5 w-5 cursor-pointer"
            onChange={() => handleDriverSelection(driver)}
          />
          <div className="w-1/2 flex justify-between items-center">
            <div className="w-2/3">
              <DriverNameCell
                name={driver.name}
                avatarUrl={driver.url}
                isAvailable={driver.isActive}
                row={driver}
                driver={driver.driver as Driver | undefined}
              />
            </div>
            <div className="w-1/3">{renderIsOffToday(driver)}</div>
          </div>
          {selectedDriversByDate[selectedStopDate]?.some(
            (d) => d.id === driver.id
          ) && (
            <div className="flex flex-col items-center w-1/4">
              <RangeSlider
                value={driverSpeeds[driver.id] || undefined}
                min={0.5}
                max={2.0}
                step={0.1}
                onChange={(e) =>
                  handleDriverSpeedChange(driver.id, parseFloat(e.target.value))
                }
                className="w-full"
              />
              <span className="text-sm text-gray-600">
                {driverSpeeds[driver.id] ? `${driverSpeeds[driver.id]}x` : null}
              </span>
            </div>
          )}
        </div>
      ))}
    </Loading>
  );
}

export default SelectDriversCreateRoutingJobSlide;
