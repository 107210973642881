import React from "react";
import { ShipmentRow, TableActionDropdownItem } from "@src/shipments/types";
import {
  UserMinusIcon,
  TrashIcon,
  ArrowsUpDownIcon,
  XMarkIcon,
  PencilSquareIcon,
  NewspaperIcon,
  EllipsisVerticalIcon,
  ClipboardDocumentCheckIcon,
  ChevronDoubleLeftIcon,
  ArrowsRightLeftIcon,
  PaperClipIcon,
} from "@heroicons/react/24/outline";
import { useModalsContext } from "../context/ShipmentsTableModalsContext";
import { useShipmentsTableContext } from "../context/ShipmentsTableContext";
import { OrdersPageModalNames } from "../utils/ordersPageModalNames";
import {
  Shipment,
  ShipmentStatus,
} from "@api/graphql/generated/generated-types";
import { useShipmentsCoreDataContext } from "../context/ShipmentsCoreData";
import { useShipmentSelectionContext } from "../context/ShipmentSelectionContext";
import { StatefulPopover, PLACEMENT } from "baseui/popover";
import { StatefulMenu } from "baseui/menu";
import { Button, KIND, SHAPE } from "baseui/button";
import { PencilIcon } from "@heroicons/react/24/solid";

type Props = {
  row: any;
};

function ShipmentsTableDropDown({ row }: Props) {
  const {
    setSelectedArtifactsAtStops,
    setModalOpen,
    setSelectedShipmentIdToUnassign,
    setSelectedShipmentIdToDelete,
    setSelectedShipmentIdToReset,
    setSelectedShipmentIdToFail,
    setSelectedShipmentIdToForceComplete,
    setSelectedShipmentIdPod,
  } = useModalsContext();
  const { setSelectedShipmentRowToChangeRouting } = useShipmentsTableContext();
  const { setShipmentSelectedForAction, handleSelectRow } =
    useShipmentSelectionContext();
  const { shipmentsApiResponse } = useShipmentsCoreDataContext();
  const NON_DESTRUCTIVE_DROPDOWN_ITEMS: TableActionDropdownItem[] = [
    {
      key: "viewPod",
      label: "View POD",
      icon: <NewspaperIcon className="w-5 h-5" />,
      onClick: (row: ShipmentRow) => {
        setSelectedShipmentIdPod(row?.id!);
        setSelectedArtifactsAtStops(row?.artifactsAtStops || []);
        setModalOpen(true, OrdersPageModalNames.POD);
      },
      disabled: (row: ShipmentRow) => false,
      tooltip: (row: ShipmentRow) => {
        return undefined;
      },
    },
    {
      key: "changeRouting",
      label: "Change Routing",
      icon: <ArrowsUpDownIcon className="w-5 h-5" />,
      onClick: (row: ShipmentRow) => {
        if (!row?.canChangeRouting) return;
        setSelectedShipmentRowToChangeRouting(row);
        setModalOpen(true, OrdersPageModalNames.CHANGE_ROUTING);
        setShipmentSelectedForAction(row.shipment);
      },
      disabled: (row: ShipmentRow) => !row?.canChangeRouting,
      tooltip: (row: ShipmentRow) => {
        if (!row?.canChangeRouting) {
          return `The order is in ${row?.status} status.`;
        }
        return undefined;
      },
    },
    {
      key: "unassignDriver",
      label: "Unassign Driver",
      icon: <UserMinusIcon className="w-5 h-5" />,
      onClick: (row: ShipmentRow) => {
        if (!row?.canUnassignDriver) return;
        setSelectedShipmentIdToUnassign(row?.id!);
        setModalOpen(true, OrdersPageModalNames.UNASSIGN);
      },
      disabled: (row: ShipmentRow) => !row?.canUnassignDriver,
      tooltip: (row: ShipmentRow) => {
        if (!row?.canUnassignDriver) {
          return `The order is in ${row?.status} status.`;
        }
        return undefined;
      },
    },
    {
      key: "changeDriver",
      label: "Change Driver",
      icon: <ArrowsRightLeftIcon className="w-5 h-5" />,
      onClick: (row: ShipmentRow) => {
        if (row?.shipment?.status === ShipmentStatus.Completed) return;
        setModalOpen(true, OrdersPageModalNames.CHANGE_DRIVER);
      },
      disabled: (row: ShipmentRow) =>
        row?.shipment?.status === ShipmentStatus.Completed ||
        row?.shipment?.status === ShipmentStatus.Created,
      tooltip: (row: ShipmentRow) => {
        if (row?.shipment?.status === ShipmentStatus.Completed) {
          return `The order is in ${row?.status} status.`;
        }
        return undefined;
      },
    },
    {
      key: "edit",
      label: "Edit",
      icon: <PencilSquareIcon className="w-5 h-5" />,
      onClick: () => {
        setModalOpen(true, OrdersPageModalNames.EDIT);
        const selectedShipment = shipmentsApiResponse?.shipments?.edges?.find(
          (shipmentEdge) => {
            if (shipmentEdge?.node) {
              return shipmentEdge.node.id === row?.id;
            }
          }
        )?.node as Shipment;
        setShipmentSelectedForAction(selectedShipment);
      },
      disabled: (row: ShipmentRow) => {
        return (
          row?.status === ShipmentStatus.Completed ||
          row?.status === ShipmentStatus.InTransit
        );
      },
      tooltip: () => {
        return undefined;
      },
    },
    {
      key: "orderAttachments",
      label: "Order Attachments",
      icon: <PaperClipIcon className="w-5 h-5" />,
      onClick: (row: ShipmentRow) => {
        setModalOpen(true, OrdersPageModalNames.ORDER_ATTACHMENTS);
        const selectedShipment = shipmentsApiResponse?.shipments?.edges?.find(
          (shipmentEdge) => {
            if (shipmentEdge?.node) {
              return shipmentEdge.node.id === row?.id;
            }
          }
        )?.node as Shipment;
        setShipmentSelectedForAction(selectedShipment);
      },
      disabled: (row: ShipmentRow) => false,
      tooltip: (row: ShipmentRow) => {
        return undefined;
      },
    },
  ];
  const DESTRUCTIVE_DROPDOWN_ITEMS: TableActionDropdownItem[] = [
    {
      key: "OVERRIDE_POD",
      label: "Override POD",
      icon: <PencilIcon className="w-5 h-5" />,
      onClick: (row: ShipmentRow) => {
        handleSelectRow(row, () => {
          setModalOpen(true, OrdersPageModalNames.OVERRIDE_POD);
        });
      },
      disabled: (row: ShipmentRow) => false,
      tooltip: (row: ShipmentRow) => {
        return undefined;
      },
    },
    {
      key: "FORCE_COMPLETE",
      label: "Force Complete",
      icon: <ClipboardDocumentCheckIcon className="w-5 h-5" />,
      onClick: (row: ShipmentRow) => {
        if (!row?.canForceCompleteOrder) return;
        setSelectedShipmentIdToForceComplete(row?.id!);
        setModalOpen(true, OrdersPageModalNames.FORCE_COMPLETE);
      },
      disabled: (row: ShipmentRow) => !row?.canForceCompleteOrder,
      tooltip: (row: ShipmentRow) => {
        if (!row?.canForceCompleteOrder) {
          return `The order is in ${row?.status} status.`;
        }
        return undefined;
      },
    },
    {
      key: "RESET",
      label: "Reset",
      icon: <ChevronDoubleLeftIcon className="w-5 h-5" />,
      onClick: (row: ShipmentRow) => {
        setSelectedShipmentIdToReset(row?.id!);
        setModalOpen(true, OrdersPageModalNames.RESET);
      },
      disabled: (row: ShipmentRow) => false,
      tooltip: (row: ShipmentRow) => {
        return `Click to revert this delivery to its initial state, as if it was just created. All progress and modifications will be discarded.`;
      },
    },
    {
      key: "DELETE",
      label: "Delete",
      icon: <TrashIcon className="w-5 h-5" />,
      onClick: (row: ShipmentRow) => {
        if (!row?.canDeleteOrder) return;
        setSelectedShipmentIdToDelete(row?.id!);
        setModalOpen(true, OrdersPageModalNames.DELETE);
      },
      disabled: (row: ShipmentRow) => !row?.canDeleteOrder,
      tooltip: (row: ShipmentRow) => {
        if (!row?.canDeleteOrder) {
          return `The order is in ${row?.status} status.`;
        }
        return undefined;
      },
    },
    {
      key: "fail",
      label: "Mark as failed",
      icon: <XMarkIcon className="w-5 h-5" />,
      onClick: (row: ShipmentRow) => {
        if (!row?.canFailOrder) return;
        setSelectedShipmentIdToFail(row?.id!);
        setModalOpen(true, OrdersPageModalNames.FAIL);
      },
      disabled: (row: ShipmentRow) => !row?.canFailOrder,
      tooltip: (row: ShipmentRow) => {
        if (!row?.canFailOrder) {
          return `The order is in ${row?.status} status.`;
        }
        return undefined;
      },
    },
  ];
  const handleSelectionChange = (selectedItem: TableActionDropdownItem) => {
    if (selectedItem.disabled && selectedItem.disabled(row)) {
      return;
    }
    selectedItem.onClick(row);
  };

  const renderMenuItems = () => {
    return NON_DESTRUCTIVE_DROPDOWN_ITEMS.concat(
      DESTRUCTIVE_DROPDOWN_ITEMS
    ).map((item) => ({
      ...item,
      disabled: item.disabled && item.disabled(row),
      label: (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            color: [
              "DELETE",
              "FORCE_COMPLETE",
              "RESET",
              "fail",
              "OVERRIDE_POD",
            ].includes(item.key)
              ? "red"
              : "inherit",
          }}
        >
          {item.icon}
          <span style={{ marginLeft: "10px" }}>{item.label}</span>
        </div>
      ),
    }));
  };

  return (
    <StatefulPopover
      focusLock
      placement={PLACEMENT.bottomRight}
      overrides={{
        Body: {
          style: { zIndex: 20 },
        },
      }}
      content={({ close }) => (
        <StatefulMenu
          items={renderMenuItems()}
          onItemSelect={({ item }) => {
            handleSelectionChange(item);
            close();
          }}
          overrides={{
            List: {
              style: { width: "200px" },
            },
          }}
        />
      )}
    >
      <Button
        shape={SHAPE.circle}
        kind={KIND.tertiary}
        style={{ height: 30, width: 30 }}
      >
        <EllipsisVerticalIcon
          className="mx-auto h-6 w-6 text-slate-500 hover:text-slate-800"
          aria-hidden="true"
        />
      </Button>
    </StatefulPopover>
  );
}

export default ShipmentsTableDropDown;
