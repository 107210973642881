import {
  BaseModal,
  BaseModalBody,
  BaseModalFooter,
  BaseModalHeader,
  Loading,
} from "@src/common/components";
import CourieButton from "@src/common/components/Button";
import React, { useState, useEffect, useContext, useCallback } from "react";
import SelectDriversCreateRoutingJobSlide from "./SelectDriversCreateRoutingJobSlide";
import { DriverOption } from "../DriverFilterSelector";
import { useShipmentSelectionContext } from "@src/shipments/context/ShipmentSelectionContext";
import { useShipmentsCoreDataContext } from "@src/shipments/context/ShipmentsCoreData";
import {
  DriverDefaultRoutingConfiguration,
  RouteOptimizationObjectiveType,
  RouteOptimizationVehicleDistributionType,
  RoutingJobCreate,
  RoutingJobShipmentSelectorType,
  RoutingJobStatus,
  RoutingVehicleCreate,
  ShipmentStatus,
  useCreateRoutingJobMutation,
  useGetDriverDefaultRoutingConfigurationsLazyQuery,
  useUniqueStopDatesLazyQuery,
} from "@api/graphql/generated/generated-types";
import { AuthContext } from "@src/auth/components/AuthProvider";
import OptimizationSettingsCreateRoutingJobSlide from "./OptimizationSettingsCreateRoutingJobSlide";
import { FormProvider, useForm } from "react-hook-form";
import { useCourieStore } from "@src/common/lib/store";
import { showErrorToast } from "@src/common/lib/NetworkErrorHandling";
import RoutingPenaltySettingsCreateRoutingJobSlide from "./RoutingPenaltySettingsCreateRoutingJobSlide";
import HeaderContent from "./HeaderContent";
import moment from "moment";
import { useShipmentFiltersContext } from "@src/shipments/context/ShipmentFiltersContext";
import { useRouter } from "next/router";

export enum CreateRoutingJobModalSlides {
  SELECT_DRIVERS = "SELECT_DRIVERS",
  OPTIMIZATION_SETTINGS = "OPTIMIZATION_SETTINGS",
  ROUTING_PENALTY_SETTINGS = "ROUTING_PENALTY_SETTINGS",
}

type Props = {
  onClose: () => void;
  setIsModalOpen: (open: boolean) => void;
  isModalOpen: boolean;
  onSuccess: () => void;
};

export type SelectedDriversByDate = {
  [key: string]: DriverOption[];
};

function CreateRoutingJobModal({ isModalOpen, onClose, onSuccess }: Props) {
  const router = useRouter();
  const { showToast } = useCourieStore();
  const { courierId, isEmployee, courieUser, customClaims } =
    useContext(AuthContext);
  const { checkedShipments, isAllUnassignedChecked } =
    useShipmentSelectionContext();
  const { rows: shipmentRows } = useShipmentsCoreDataContext();
  const { minDate, maxDate } = useShipmentFiltersContext();
  const methods = useForm({
    defaultValues: {
      startAddresses: {},
      endAddresses: {},
      startTimes: {},
      endTimes: {},
      useCurrentLocationAtStart: {},
    },
  });
  const [
    createRoutingJobMutation,
    { data, loading: createRoutingJobLoading, error },
  ] = useCreateRoutingJobMutation();
  const [
    getDriverDefaultRoutingConfigurations,
    {
      data: driverDefaultRoutingConfigurationsQuery,
      loading: driverDefaultRoutingConfigurationsLoading,
    },
  ] = useGetDriverDefaultRoutingConfigurationsLazyQuery({
    fetchPolicy: "cache-and-network",
  });

  const [getUniqueStopDates, { loading: uniqueStopDatesLoading }] =
    useUniqueStopDatesLazyQuery();

  const [currentSlide, setCurrentSlide] = useState<CreateRoutingJobModalSlides>(
    CreateRoutingJobModalSlides.SELECT_DRIVERS
  );
  const [selectedDriversByDate, setSelectedDriversByDate] =
    useState<SelectedDriversByDate>({});
  const [driverSpeeds, setDriverSpeeds] = useState<{ [key: string]: number }>(
    {}
  );
  const [stopDates, setStopDates] = useState<string[]>([]);
  const [selectedStopDate, setSelectedStopDate] = useState<string | null>(null);
  const [earlyPenaltyLevel, setEarlyPenaltyLevel] = useState(0);
  const [latePenaltyLevel, setLatePenaltyLevel] = useState(0);
  const [isPrimaryButtonDisabled, setIsPrimaryButtonDisabled] = useState(false);
  const [formRoutingConfig, setFormRoutingConfig] = useState<
    DriverDefaultRoutingConfiguration[]
  >([]);
  const [errors, setErrors] = useState<Record<string, string>>({});
  const [objectiveType, setObjectiveType] =
    useState<RouteOptimizationObjectiveType>(
      RouteOptimizationObjectiveType.Distance
    );
  const [vehicleDistributionType, setVehicleDistributionType] =
    useState<RouteOptimizationVehicleDistributionType>(
      RouteOptimizationVehicleDistributionType.None
    );

  useEffect(() => {
    if (courierId && isAllUnassignedChecked) {
      getUniqueStopDates({
        variables: {
          courierId: courierId,
          shipmentStatusFilter: [ShipmentStatus.Created],
          maxShipmentDate: moment(maxDate).format("YYYY-MM-DD"),
          minExpectedCompletionDate: moment(minDate).format("YYYY-MM-DD"),
        },
      }).then((res) => {
        const data = res.data?.uniqueStopDates;
        data && setStopDates(data);
      });
    }
  }, [courierId, isAllUnassignedChecked]);

  useEffect(() => {
    if (isModalOpen) {
      const checkedShipmentRows = shipmentRows.filter(
        (row) => row && checkedShipments.includes(row.id)
      );

      const multiDayShipmentRows = checkedShipmentRows.filter(
        (row) => row?.isMultiDay
      );
      if (multiDayShipmentRows.length > 0) {
        const stopDates = Array.from(
          new Set(
            multiDayShipmentRows.flatMap((row) =>
              row?.shipment?.stops.map((stop) => stop.stopDate)
            )
          )
        ).sort((a, b) => moment(a).diff(moment(b)));
        setStopDates(stopDates);
      }
      if (multiDayShipmentRows.length === 0) {
        setStopDates([checkedShipmentRows[0]?.shipment?.shipmentDate]);
      }
    }
  }, [isModalOpen]);

  useEffect(() => {
    if (stopDates.length > 0 && !selectedStopDate) {
      setSelectedStopDate(stopDates[0]);
    }
  }, [stopDates, selectedStopDate]);

  const onSubmit = () => {
    if (!courierId) return;
    const maxDateString = moment(maxDate).format("YYYY-MM-DD");
    const minDateString = moment(minDate).format("YYYY-MM-DD");

    const jobCreate: RoutingJobCreate = {
      courierId,
      createdBy: isEmployee
        ? "EMPLOYEE"
        : courieUser?.id || customClaims?.courieUserId || undefined,
      earlyPenaltyLevel: earlyPenaltyLevel,
      latePenaltyLevel: latePenaltyLevel,
      shipmentIds: isAllUnassignedChecked
        ? []
        : checkedShipments.map((id) => id),
      shipmentSelector: isAllUnassignedChecked
        ? {
            maxShipmentDate: maxDateString,
            minExpectedCompletionDate: minDateString,
            type: RoutingJobShipmentSelectorType.Unassigned,
          }
        : null,
      objectiveType: objectiveType,
      vehicleDistributionType: vehicleDistributionType,
    };
    const vehicleCreates: RoutingVehicleCreate[] = [];

    formRoutingConfig.map((config) => {
      vehicleCreates.push({
        date: config.date,
        driverId: config.driverId,
        driverSpeed: driverSpeeds[config.driverId],
        endLocationId: config.endLocationId,
        endTime: config.endTime,
        startLocationId: config.startLocationId,
        startTime: config.startTime,
        useCurrentLocationIdAtStart: config.useCurrentLocationAtStart
          ? true
          : false,
      });
    });

    createRoutingJobMutation({
      variables: {
        jobCreate,
        vehicleCreates,
      },
    })
      .then((res) => {
        const data = res.data?.createRoutingJob;
        if (data?.status === RoutingJobStatus.Ready) {
          router.push(`/routingjob/${data.id}`);
          return;
        }
        showToast({
          message:
            "Optimization in progress! This may take a few minutes—check back soon.",
          type: "success",
        });
        onClose();
        onSuccess();
      })
      .catch((error) => {
        showErrorToast(error, showToast);
      });
  };

  const validateStartTimes = useCallback(() => {
    const newErrors: Record<string, string> = {};
    formRoutingConfig.forEach((config) => {
      if (!config.startTime) {
        newErrors[`${config.driverId}-${config.date}`] =
          "Start time is required";
      }
    });
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  }, [formRoutingConfig]);

  const handleNextSlide = () => {
    const currentIndex = stopDates.indexOf(selectedStopDate!);
    if (currentIndex < stopDates.length - 1) {
      // Move to the next stop date
      if (currentSlide === CreateRoutingJobModalSlides.OPTIMIZATION_SETTINGS) {
        if (validateStartTimes()) {
          setSelectedStopDate(stopDates[currentIndex + 1]);
        }
      } else {
        setSelectedStopDate(stopDates[currentIndex + 1]);
      }
    } else {
      if (currentSlide === CreateRoutingJobModalSlides.OPTIMIZATION_SETTINGS) {
        if (validateStartTimes()) {
          setCurrentSlide(CreateRoutingJobModalSlides.ROUTING_PENALTY_SETTINGS);
        }
        return;
      }
      if (
        currentSlide === CreateRoutingJobModalSlides.ROUTING_PENALTY_SETTINGS
      ) {
        // Submit the form
        onSubmit();
        return;
      }
      // Move to the next slide
      setCurrentSlide(CreateRoutingJobModalSlides.OPTIMIZATION_SETTINGS);
      setSelectedStopDate(stopDates[0]);
    }
  };

  const handlePreviousSlide = () => {
    const currentIndex = stopDates.indexOf(selectedStopDate!);

    switch (currentSlide) {
      case CreateRoutingJobModalSlides.ROUTING_PENALTY_SETTINGS:
        if (currentIndex > 0) {
          setSelectedStopDate(stopDates[0]);
        }
        setCurrentSlide(CreateRoutingJobModalSlides.OPTIMIZATION_SETTINGS);
        break;

      case CreateRoutingJobModalSlides.OPTIMIZATION_SETTINGS:
        if (currentIndex > 0) {
          setSelectedStopDate(stopDates[currentIndex - 1]);
        } else {
          setCurrentSlide(CreateRoutingJobModalSlides.SELECT_DRIVERS);
        }
        break;

      default:
        if (currentIndex > 0) {
          setSelectedStopDate(stopDates[currentIndex - 1]);
        } else {
          setCurrentSlide(CreateRoutingJobModalSlides.SELECT_DRIVERS);
        }
        break;
    }
  };

  return (
    <BaseModal
      isOpen={isModalOpen}
      onClose={onClose}
      overrides={{
        Dialog: {
          style: {
            width: "fit-content",
            maxWidth: "90vw",
          },
        },
      }}
    >
      <FormProvider {...methods}>
        <BaseModalHeader>
          {currentSlide === CreateRoutingJobModalSlides.SELECT_DRIVERS ? (
            <HeaderContent
              title="Please Select Drivers"
              selectedStopDate={selectedStopDate}
              stopDates={stopDates}
            />
          ) : currentSlide ===
            CreateRoutingJobModalSlides.OPTIMIZATION_SETTINGS ? (
            <Loading loading={uniqueStopDatesLoading}>
              <HeaderContent
                title="Optimization Settings"
                selectedStopDate={selectedStopDate}
                stopDates={stopDates}
              />
            </Loading>
          ) : (
            "Optimization Settings"
          )}
        </BaseModalHeader>
        <BaseModalBody>
          {currentSlide === CreateRoutingJobModalSlides.SELECT_DRIVERS && (
            <>
              {selectedStopDate && (
                <SelectDriversCreateRoutingJobSlide
                  selectedDriversByDate={selectedDriversByDate}
                  setSelectedDriversByDate={setSelectedDriversByDate}
                  driverSpeeds={driverSpeeds}
                  setDriverSpeeds={setDriverSpeeds}
                  selectedStopDate={selectedStopDate}
                  getDriverDefaultRoutingConfigurations={
                    getDriverDefaultRoutingConfigurations
                  }
                  driverDefaultRoutingConfigurationsLoading={
                    driverDefaultRoutingConfigurationsLoading
                  }
                  driverDefaultRoutingConfigurationsQuery={
                    driverDefaultRoutingConfigurationsQuery
                  }
                />
              )}
            </>
          )}
          {currentSlide === CreateRoutingJobModalSlides.OPTIMIZATION_SETTINGS &&
            selectedStopDate && (
              <>
                <OptimizationSettingsCreateRoutingJobSlide
                  selectedDriversByDate={selectedDriversByDate}
                  selectedStopDate={selectedStopDate}
                  setIsPrimaryButtonDisabled={setIsPrimaryButtonDisabled}
                  setFormRoutingConfig={setFormRoutingConfig}
                  formRoutingConfig={formRoutingConfig}
                  errors={errors}
                  getDriverDefaultRoutingConfigurations={
                    getDriverDefaultRoutingConfigurations
                  }
                  driverDefaultRoutingConfigurationsLoading={
                    driverDefaultRoutingConfigurationsLoading
                  }
                />
              </>
            )}
          {currentSlide ===
            CreateRoutingJobModalSlides.ROUTING_PENALTY_SETTINGS && (
            <RoutingPenaltySettingsCreateRoutingJobSlide
              objectiveType={objectiveType}
              setObjectiveType={setObjectiveType}
              vehicleDistributionType={vehicleDistributionType}
              setVehicleDistributionType={setVehicleDistributionType}
            />
          )}
        </BaseModalBody>
        <BaseModalFooter>
          <div className="flex justify-between">
            {currentSlide === CreateRoutingJobModalSlides.SELECT_DRIVERS && (
              <>
                {stopDates.length === 0 ? (
                  <CourieButton color={"gray"} onClick={onClose}>
                    Cancel
                  </CourieButton>
                ) : selectedStopDate !== stopDates[0] ? (
                  <CourieButton color="gray" onClick={handlePreviousSlide}>
                    Back
                  </CourieButton>
                ) : (
                  <div></div>
                )}
                {selectedStopDate && (
                  <CourieButton
                    onClick={handleNextSlide}
                    disabled={
                      !selectedDriversByDate[selectedStopDate] ||
                      selectedDriversByDate[selectedStopDate].length === 0 ||
                      stopDates.some(
                        (date) =>
                          selectedDriversByDate[date] &&
                          selectedDriversByDate[date].length === 0
                      ) ||
                      isPrimaryButtonDisabled
                    }
                  >
                    Next
                  </CourieButton>
                )}
              </>
            )}
            {currentSlide ===
              CreateRoutingJobModalSlides.OPTIMIZATION_SETTINGS && (
              <>
                <CourieButton color="gray" onClick={handlePreviousSlide}>
                  Back
                </CourieButton>
                <CourieButton
                  onClick={handleNextSlide}
                  disabled={isPrimaryButtonDisabled}
                >
                  Next
                </CourieButton>
              </>
            )}
            {currentSlide ===
              CreateRoutingJobModalSlides.ROUTING_PENALTY_SETTINGS && (
              <>
                <CourieButton color="gray" onClick={handlePreviousSlide}>
                  Back
                </CourieButton>
                <CourieButton
                  onClick={handleNextSlide}
                  isProcessing={createRoutingJobLoading}
                >
                  Submit
                </CourieButton>
              </>
            )}
          </div>
        </BaseModalFooter>
      </FormProvider>
    </BaseModal>
  );
}

export default CreateRoutingJobModal;
