import React, { useState } from "react";
import { Tooltip } from "@src/common/components";
import { Table } from "@src/common/components/Table/Table";
import classNames from "classnames";
import {
  RecurrenceType,
  ShipmentsSortField,
  ShipmentStatus,
} from "@api/graphql/generated/generated-types";
import { PackageTooltip } from "./PackageTooltip";
import { SORTED_COLUMN_BG } from "@src/common/lib/tableUtils";
import { ShipmentsTableRowCell } from "./ShipmentsTableRowCell";
import StopStatusCell from "./StopStatusCell";
import { ShipmentStatusCell } from "./ShipmentStatusCell";
import ShipmentsTableDropDown from "./ShipmentsTableDropDown";
import RecurringInstanceOrderIcon from "./RecurringInstanceOrderIcon";
import { OrdersPageModalNames } from "../utils/ordersPageModalNames";
import { StopTimeStatusBadge } from "./StopTimeStatusBadge";
import DisplayServiceType from "@src/servicetypes/components/DisplayServiceType";
import { ClockIcon } from "@heroicons/react/24/outline";
import RoundTripShipmentLabel from "../RoundTripShipmentIcon";
import MarkShipmentAsClosedButton from "./MarkShipmentAsClosedButton";
import UncloseShipmentButton from "./UncloseShipmentButton";
import { useShipmentSelectionContext } from "../context/ShipmentSelectionContext";
import { ShipmentRow, ShipmentsPrimaryFilterType } from "../types";
import { useHeaderSorting } from "@src/common/hooks/useHeaderSorting";
import { useModalsContext } from "../context/ShipmentsTableModalsContext";
import { hoverActions } from "../constants/hoverActions";
import { useShipmentsTableContext } from "../context/ShipmentsTableContext";
import { MoonIcon } from "@heroicons/react/20/solid";
import useDownloadOrdersPdf from "@src/common/hooks/useDownloadOrdersPdf";
import { PLACEMENT, StatefulTooltip } from "baseui/tooltip";
import moment from "moment";
import { useShipmentFiltersContext } from "../context/ShipmentFiltersContext";
import { Checkbox } from "flowbite-react";

type Props = {
  row: ShipmentRow;
  shipmentId: string | undefined;
};

// setting this to prevent column moving when hover buttons are shown
const FIXED_WIDTH_TO_CONTAIN_BOTH_HOVER_BUTTONS_AND_PACKAGEINFO = 100;

function OrderRow({ row, shipmentId }: Props) {
  // contexts
  const {
    selectedRow,
    handleSelectRow,
    setShipmentSelectedForAction,
    setCheckedShipments,
    checkedShipments,
    isAllUnassignedChecked,
    setIsAllUnassignedChecked,
    setSelectedRow,
  } = useShipmentSelectionContext();
  const { areFieldsSorted } = useHeaderSorting();
  const {
    setSelectedArtifactsAtStops,
    setModalOpen,
    setSelectedShipmentIdToUnassign,
    setSelectedShipmentIdPod,
  } = useModalsContext();
  const { minDate, maxDate, selectedPrimaryFilter } =
    useShipmentFiltersContext();
  const { setSelectedShipmentRowToChangeRouting } = useShipmentsTableContext();
  const { handleDownloadPDF } = useDownloadOrdersPdf();
  // states
  const [lastClosedShipmentIds, setLastClosedShipmentIds] = useState<string[]>(
    []
  );
  const [lastUnclosedShipmentIds, setLastUnclosedShipmentIds] = useState<
    string[]
  >([]);
  const [isHovered, setIsHovered] = useState(false);

  const showDate =
    !minDate || !maxDate || !moment(minDate).isSame(maxDate, "day");
  const showCheckbox =
    selectedPrimaryFilter === ShipmentsPrimaryFilterType.UNASSIGNED;
  const showOrderAttachmentsLabel =
    row?.shipment && row.shipment?.order.orderAttachments.length > 0;

  const handleHoverAction = (action) => {
    handleSelectRow(row, () => {
      setShipmentSelectedForAction(row?.shipment);
      switch (action.key) {
        case "viewPod":
          if (row?.artifactsAtStops.length === 0) return;
          setSelectedArtifactsAtStops(row?.artifactsAtStops || []);
          setSelectedShipmentIdPod(row?.id!);
          setModalOpen(true, OrdersPageModalNames.POD);
          break;
        case "changeRouting":
          if (!row?.canChangeRouting) return;
          setSelectedShipmentRowToChangeRouting(row);
          setModalOpen(true, OrdersPageModalNames.CHANGE_ROUTING);
          break;
        case "unassignDriver":
          if (!row?.canUnassignDriver) return;
          setSelectedShipmentIdToUnassign(row?.id!);
          setModalOpen(true, OrdersPageModalNames.UNASSIGN);
          break;
        case "edit":
          setModalOpen(true, OrdersPageModalNames.EDIT);
          break;
        case "downloadOrderPdf":
          handleDownloadPDF(row?.shipment?.order.id!);
          break;
        case "viewOrderAttachments":
          setModalOpen(true, OrdersPageModalNames.ORDER_ATTACHMENTS);
          break;
      }
    });
  };

  const handleToggleShipmentCheck = (shipmentId: string) => {
    if (checkedShipments.includes(shipmentId)) {
      setCheckedShipments(checkedShipments.filter((id) => id !== shipmentId));
      if (isAllUnassignedChecked) {
        setIsAllUnassignedChecked(false);
      }
    } else {
      setCheckedShipments([...checkedShipments, shipmentId]);
    }
  };

  const returnActionButtons = () => {
    const actions = hoverActions(row).filter((action) => !action.disabled); // Filter out disabled actions

    return (
      <div
        className={`inset-y-0 flex gap-2 items-center z-10`}
        style={{
          width: "auto",
        }}
      >
        {actions.map((action) => (
          <Tooltip
            key={action.key}
            className="truncate"
            content={action.tooltip || action.label}
          >
            <div
              onClick={() => {
                handleHoverAction(action);
              }}
              color={"secondary"}
              className="hover:bg-slate-300 hover:cursor-pointer p-1 rounded-full w-8 h-8 flex items-center justify-center" // Set width and height to 8 and make it flex to center the icon
            >
              {action.icon}
            </div>
          </Tooltip>
        ))}
        <ShipmentsTableDropDown row={row} />
      </div>
    );
  };

  return (
    <StatefulTooltip
      content={() => <div>{returnActionButtons()}</div>}
      returnFocus
      autoFocus
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      placement={PLACEMENT.topRight}
      overrides={{
        Body: {
          style: {
            zIndex: 20,
          },
        },
        Inner: {
          style: {
            backgroundColor: "#f0f0f0",
          },
        },
      }}
      popoverMargin={-22}
    >
      <tr
        style={{ maxWidth: 400 }}
        key={shipmentId}
        onClick={() => {
          handleSelectRow(row);
        }}
        className={classNames({
          "border-b relative": true,
          "cursor-pointer": selectedRow?.key !== row?.key,
          "bg-blue-100 ": selectedRow?.key === row?.key,
          "bg-slate-100": isHovered && checkedShipments.length === 0,
          "hover:bg-slate-100":
            selectedRow?.key !== row?.key && checkedShipments.length === 0,
          "bg-blue-100": checkedShipments.includes(shipmentId || ""),
        })}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        {showCheckbox && (
          <ShipmentsTableRowCell row={row} selectedRow={selectedRow}>
            <td className="absolute left-2.5 top-5">
              <Checkbox
                checked={
                  shipmentId ? checkedShipments.includes(shipmentId) : false
                }
                className="form-checkbox h-5 w-5 mr-2 hover:cursor-pointer"
                onClick={() =>
                  shipmentId && handleToggleShipmentCheck(shipmentId)
                }
              />
            </td>
          </ShipmentsTableRowCell>
        )}
        <ShipmentsTableRowCell
          sortedFields={[ShipmentsSortField.OrderDisplayId]}
          row={row}
          selectedRow={selectedRow}
        >
          <div className="flex flex-col text-xs items-center">
            {row?.orderDisplayId}
            <div className="flex flex-wrap justify-center items-center">
              {row?.shipmentType === "ROUND_TRIP" && (
                <div className="text-xs text-blue-600">
                  <RoundTripShipmentLabel />
                </div>
              )}
              {row?.recurrenceType === RecurrenceType.RecurringInstance && (
                <div>
                  <RecurringInstanceOrderIcon />
                </div>
              )}
              {row?.isMultiDay && (
                <Tooltip content="Multi Day">
                  <MoonIcon className="w-3.5 h-3.5" />
                </Tooltip>
              )}
              {showDate && (
                <div className="border truncate p-1 rounded-xl">
                  <span>{moment(row?.shipmentDate).format("MMM DD")}</span>
                </div>
              )}
            </div>
          </div>
        </ShipmentsTableRowCell>

        <ShipmentsTableRowCell
          sortedFields={[ShipmentsSortField.EndCustomerName]}
          row={row}
          selectedRow={selectedRow}
          style={{ maxWidth: 100 }}
        >
          <div className="text-xs font-bold truncate">
            {row?.customerDisplayName}
          </div>
          <div className="text-xs">
            {row?.serviceType && (
              <DisplayServiceType
                serviceType={row?.serviceType}
                color={row?.serviceTypeColor}
              />
            )}
          </div>
        </ShipmentsTableRowCell>

        <ShipmentsTableRowCell
          sortedFields={[
            ShipmentsSortField.OriginDispatchZone,
            ShipmentsSortField.OriginZip,
            ShipmentsSortField.OriginStreetAddress,
            ShipmentsSortField.OriginCity,
            ShipmentsSortField.PickUpCompanyName,
          ]}
          row={row}
          selectedRow={selectedRow}
        >
          {row?.pickup && (
            <StopStatusCell
              stopBadge={row?.pickup}
              dispatchZone={row?.origin.dispatchZone}
              eta={row?.pickup.eta}
              isLikelyLate={row?.pickup.isLikelyLate}
              taskStatusList={row?.pickup.taskStatusList}
              arrivedAt={row?.pickup.arrivedAt}
              completedAt={row?.pickup.completedAt}
              completedPrefix={"Picked up"}
              pickUpStops={row.pickup.pickUpStops}
            />
          )}
        </ShipmentsTableRowCell>

        <ShipmentsTableRowCell
          sortedFields={[
            ShipmentsSortField.DestinationDispatchZone,
            ShipmentsSortField.DestinationZip,
            ShipmentsSortField.DestinationStreetAddress,
            ShipmentsSortField.DestinationCity,
            ShipmentsSortField.DropOffCompanyName,
          ]}
          row={row}
          selectedRow={selectedRow}
        >
          {row?.dropoff && (
            <StopStatusCell
              stopBadge={row?.dropoff}
              dispatchZone={row?.destination.dispatchZone}
              eta={row?.dropoff.eta}
              isLikelyLate={row?.dropoff.isLikelyLate}
              taskStatusList={row?.dropoff.taskStatusList}
              arrivedAt={row?.dropoff.arrivedAt}
              completedAt={row?.dropoff.completedAt}
              completedPrefix={"Delivered"}
              dropOffStops={row.dropoff.dropOffStops}
            />
          )}
        </ShipmentsTableRowCell>

        <ShipmentsTableRowCell
          sortedFields={[ShipmentsSortField.PickUpReadyTime]}
          row={row}
          selectedRow={selectedRow}
        >
          <Table.Cell
            className={classNames({
              "px-2": true,
              [SORTED_COLUMN_BG]: areFieldsSorted([
                ShipmentsSortField.PickUpReadyTime,
              ]),
              "bg-blue-100 ": selectedRow?.key === row?.key,
            })}
          >
            <p className="text-xs truncate font-bold mb-0.5">
              {row?.pickup?.timeWindowText}
            </p>
            {row?.pickup && (
              <StopTimeStatusBadge
                className="w-fit"
                color={row?.pickup.badgeMeta.backgroundColor}
                size={"xs"}
                icon={ClockIcon as React.FC<React.SVGProps<SVGSVGElement>>}
                showBadge={row?.pickup.badgeMeta.shouldShowBadge}
                tooltipContent={row?.pickup.timeWindowText || ""}
              >
                <div className="font-normal truncate text-xs">
                  {row?.pickup.badgeMeta.badgeText}
                </div>
              </StopTimeStatusBadge>
            )}
          </Table.Cell>
        </ShipmentsTableRowCell>
        <ShipmentsTableRowCell
          sortedFields={[ShipmentsSortField.DropOffDueTime]}
          row={row}
          selectedRow={selectedRow}
        >
          <Table.Cell
            className={classNames({
              "px-2 flex flex-col justify-between h-full": true,
              [SORTED_COLUMN_BG]: areFieldsSorted([
                ShipmentsSortField.DropOffDueTime,
                ShipmentsSortField.DropOffReadyTime,
              ]),
              "bg-blue-100 ": selectedRow?.key === row?.key,
            })}
          >
            <p className="text-xs truncate font-bold mb-0.5">
              {row?.dropoff?.timeWindowText}
            </p>
            {row?.dropoff && (
              <StopTimeStatusBadge
                className="w-fit"
                color={row?.dropoff.badgeMeta.backgroundColor}
                size={"xs"}
                icon={ClockIcon as React.FC<React.SVGProps<SVGSVGElement>>}
                showBadge={row?.dropoff.badgeMeta.shouldShowBadge}
                tooltipContent={row?.dropoff.timeWindowText || ""}
              >
                <div className="font-normal text-xs truncate">
                  {row?.dropoff.badgeMeta.badgeText}
                </div>
              </StopTimeStatusBadge>
            )}
          </Table.Cell>
        </ShipmentsTableRowCell>

        <ShipmentsTableRowCell
          sortedFields={[
            ShipmentsSortField.DriverName,
            ShipmentsSortField.Status,
          ]}
          row={row}
          selectedRow={selectedRow}
        >
          <ShipmentStatusCell
            status={row?.status}
            StatusIcon={row?.StatusIcon}
            driverPhoto={row?.driverPhotoUrl}
            driverName={row?.driverName}
            driver={row?.driverMapItem}
            isCompleted={row?.isCompleted}
            row={row}
          />
        </ShipmentsTableRowCell>

        <ShipmentsTableRowCell row={row} selectedRow={selectedRow}>
          <div
            className="flex flex-col gap-1 text-xs py-0.5"
            style={{
              width: FIXED_WIDTH_TO_CONTAIN_BOTH_HOVER_BUTTONS_AND_PACKAGEINFO,
            }}
          >
            <div>{row?.package && <PackageTooltip pkg={row.package} />}</div>
            {row?.shipment?.packageType && (
              <div>{row.shipment.packageType.name}</div>
            )}
            <div className="text-xs">
              {row?.isCompleted === true &&
                row?.artifactsAtStops &&
                row?.artifactsAtStops.length === 0 && (
                  <div className="flex gap-1">
                    <div className="text-orange-400 truncate">POD Missing</div>
                  </div>
                )}
            </div>
            <p className="truncate text-blue-500 font-bold">
              {row?.shipment?.packageDescription}
            </p>
            {showOrderAttachmentsLabel && (
              <p
                className="truncate text-gray-500 font-bold hover:cursor-pointer hover:underline"
                onClick={() => {
                  setModalOpen(true, OrdersPageModalNames.ORDER_ATTACHMENTS);
                  setShipmentSelectedForAction(row.shipment);
                  setSelectedRow(row);
                }}
              >
                {row.shipment?.order.orderAttachments.length} attachments
              </p>
            )}
          </div>
        </ShipmentsTableRowCell>

        <Table.Cell
          className="px-4"
          onClick={(event) => {
            event.stopPropagation();
          }}
        >
          <div className="flex items-center gap-2">
            {row?.canCloseOrder && (
              <>
                <MarkShipmentAsClosedButton
                  onClick={() => {
                    setLastClosedShipmentIds([
                      ...lastClosedShipmentIds,
                      row.id,
                    ]);
                  }}
                  lastClosedShipmentIds={lastClosedShipmentIds}
                  setLastClosedShipmentIds={setLastClosedShipmentIds}
                  row={row}
                />
              </>
            )}
            {row?.canUncloseOrder && (
              <>
                <UncloseShipmentButton
                  onClick={() => {
                    setLastUnclosedShipmentIds([
                      ...lastUnclosedShipmentIds,
                      row.id,
                    ]);
                  }}
                  lastUnclosedShipmentIds={lastUnclosedShipmentIds}
                  setLastUnclosedShipmentIds={setLastClosedShipmentIds}
                  row={row}
                />
              </>
            )}
            <div></div>
          </div>
        </Table.Cell>
      </tr>
    </StatefulTooltip>
  );
}

export default OrderRow;
