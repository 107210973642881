import React, { useContext, useEffect, useState } from "react";
import { DriverNameCell } from "@src/common/components/DriverNameCell/DriverNameCell";
import {
  Driver,
  DriverDefaultRoutingConfiguration,
  Location,
  LocationResult,
  useGetDriverDefaultRoutingConfigurationsLazyQuery,
  useGetOrCreateLocationsMutation,
} from "@api/graphql/generated/generated-types";
import AddressInput from "@src/orders/create/components/AddressInput";
import moment from "moment";
import { HomeIcon, MapPinIcon } from "@heroicons/react/24/outline";
import {
  MapPinIcon as MapPinIconSolid,
  HomeIcon as HomeIconSolid,
} from "@heroicons/react/24/solid";
import { AuthContext } from "@src/auth/components/AuthProvider";
import { SelectedDriversByDate } from "./CreateRoutingJobModal";
import { Loading, TimePicker, Tooltip } from "@src/common/components";
import { showErrorToast } from "@src/common/lib/NetworkErrorHandling";
import { useCourieStore } from "@src/common/lib/store";
import { DriverOption } from "../DriverFilterSelector";

type Props = {
  selectedDriversByDate: SelectedDriversByDate;
  selectedStopDate: string;
  setIsPrimaryButtonDisabled: (value: boolean) => void;
  setFormRoutingConfig: React.Dispatch<
    React.SetStateAction<DriverDefaultRoutingConfiguration[]>
  >;
  formRoutingConfig: DriverDefaultRoutingConfiguration[];
  errors: Record<string, string>;
  getDriverDefaultRoutingConfigurations: any;
  driverDefaultRoutingConfigurationsLoading: boolean;
};

function OptimizationSettingsCreateRoutingJobSlide({
  selectedDriversByDate,
  selectedStopDate,
  setIsPrimaryButtonDisabled,
  setFormRoutingConfig,
  formRoutingConfig,
  errors,
  getDriverDefaultRoutingConfigurations,
  driverDefaultRoutingConfigurationsLoading,
}: Props) {
  const { showToast } = useCourieStore();
  const { courierId } = useContext(AuthContext);
  const currentSelectedDrivers = selectedDriversByDate[selectedStopDate] || [];

  useEffect(() => {
    getDriverDefaultRoutingConfigurations({
      variables: {
        driverIds: currentSelectedDrivers.map((driver) => driver.id),
        dates: selectedStopDate,
      },
    })
      .then((res) => {
        if (res.data) {
          const driverDefaultRoutingConfigurations =
            res.data.driverDefaultRoutingConfigurations;

          const filteredConfigurations =
            driverDefaultRoutingConfigurations.filter((newConfig) => {
              return !formRoutingConfig.some(
                (existingConfig) =>
                  existingConfig.driverId === newConfig.driverId &&
                  existingConfig.date === newConfig.date
              );
            });

          setFormRoutingConfig([
            ...formRoutingConfig,
            ...filteredConfigurations,
          ]);
        }
      })
      .catch((err) => {
        showErrorToast(err, showToast);
      });
  }, [selectedStopDate]);

  const [
    getOrCreateLocationsMutation,
    { loading: getOrCreateLocationsLoading },
  ] = useGetOrCreateLocationsMutation();

  useEffect(() => {
    setIsPrimaryButtonDisabled(getOrCreateLocationsLoading);
  }, [getOrCreateLocationsLoading]);

  const getDriver = (driverId: string) => {
    return currentSelectedDrivers.find((driver) => driver.id === driverId);
  };

  const handleChangeCurrentLocationAtStart = (
    driverId: string,
    date: string
  ) => {
    const founddriverRoutingConfiguration = formRoutingConfig.find(
      (config) => config.driverId === driverId && config.date === date
    );
    if (founddriverRoutingConfiguration) {
      const newFormRoutingConfig = formRoutingConfig.map((config) => {
        if (config.driverId === driverId && config.date === date) {
          return {
            ...config,
            useCurrentLocationAtStart: !config.useCurrentLocationAtStart,
            startLocation: null,
            startLocationId: null,
          };
        }
        return config;
      });
      setFormRoutingConfig(newFormRoutingConfig);
    }
  };

  const handleAddressChange = (
    address: google.maps.places.AutocompletePrediction | undefined,
    driverRoutingConfiguration: DriverDefaultRoutingConfiguration,
    key: "startLocation" | "endLocation"
  ) => {
    if (address) {
      const googlePlaceIds = address.place_id;
      getOrCreateLocationsMutation({
        variables: {
          googlePlaceIds: googlePlaceIds,
          courierId: courierId,
        },
      })
        .then((res) => {
          let suggestedLocations: LocationResult[] | undefined;
          if (res.data) {
            suggestedLocations = res.data?.getOrCreateLocations;
          }
          if (!suggestedLocations) {
            return;
          }
          const founddriverRoutingConfiguration = formRoutingConfig.find(
            (config) =>
              config.driverId === driverRoutingConfiguration.driverId &&
              config.date === driverRoutingConfiguration.date
          );
          if (founddriverRoutingConfiguration) {
            if (
              !suggestedLocations ||
              suggestedLocations.length === 0 ||
              !suggestedLocations[0]?.location
            ) {
              return;
            }
            const newFormRoutingConfig = formRoutingConfig.map((config) => {
              if (
                config.driverId === driverRoutingConfiguration.driverId &&
                config.date === driverRoutingConfiguration.date
              ) {
                if (key === "startLocation") {
                  if (
                    !suggestedLocations ||
                    suggestedLocations.length === 0 ||
                    !suggestedLocations[0]?.location
                  ) {
                    return;
                  }
                  return {
                    ...config,
                    startLocation: {
                      ...(suggestedLocations[0].location as Location),
                    },
                    startLocationId: suggestedLocations[0].location?.id,
                  };
                }
                if (key === "endLocation") {
                  if (
                    !suggestedLocations ||
                    suggestedLocations.length === 0 ||
                    !suggestedLocations[0]?.location
                  ) {
                    return;
                  }
                  return {
                    ...config,
                    endLocation: {
                      ...(suggestedLocations[0].location as Location),
                    },
                    endLocationId: suggestedLocations[0].location?.id,
                  };
                }
              }
              return config;
            });
            setFormRoutingConfig(
              newFormRoutingConfig.filter(
                (config): config is DriverDefaultRoutingConfiguration =>
                  config !== undefined
              )
            );
          }
        })
        .catch((err) => {
          showErrorToast(err, showToast);
        });
    } else {
      const founddriverRoutingConfiguration = formRoutingConfig.find(
        (config) =>
          config.driverId === driverRoutingConfiguration.driverId &&
          config.date === driverRoutingConfiguration.date
      );
      if (founddriverRoutingConfiguration) {
        const newFormRoutingConfig = formRoutingConfig.map((config) => {
          if (
            config.driverId === driverRoutingConfiguration.driverId &&
            config.date === driverRoutingConfiguration.date
          ) {
            if (key === "startLocation") {
              return {
                ...config,
                startLocation: null,
                startLocationId: null,
              };
            }
            if (key === "endLocation") {
              return {
                ...config,
                endLocation: null,
                endLocationId: null,
              };
            }
          }
          return config;
        });
        setFormRoutingConfig(newFormRoutingConfig);
      }
    }
  };

  const handleTimeChange = (
    timeValue: string | null,
    driverRoutingConfiguration: DriverDefaultRoutingConfiguration,
    key: "startTime" | "endTime"
  ) => {
    const founddriverRoutingConfiguration = formRoutingConfig.find(
      (config) =>
        config.driverId === driverRoutingConfiguration.driverId &&
        config.date === driverRoutingConfiguration.date
    );
    if (founddriverRoutingConfiguration) {
      const newFormRoutingConfig = formRoutingConfig.map((config) => {
        if (
          config.driverId === driverRoutingConfiguration.driverId &&
          config.date === driverRoutingConfiguration.date
        ) {
          return {
            ...config,
            [key]: timeValue
              ? moment(`${selectedStopDate} ${timeValue}`, "YYYY-MM-DD HH:mm")
                  .unix()
                  .toString()
              : null,
          };
        }
        return config;
      });
      setFormRoutingConfig(newFormRoutingConfig);
    }
  };

  const handleToggleHomeLocation = (
    driverRoutingConfiguration: DriverDefaultRoutingConfiguration,
    driver: DriverOption,
    key: "startLocation" | "endLocation"
  ) => {
    const founddriverRoutingConfiguration = formRoutingConfig.find(
      (config) =>
        config.driverId === driverRoutingConfiguration.driverId &&
        config.date === driverRoutingConfiguration.date
    );
    if (founddriverRoutingConfiguration) {
      const newFormRoutingConfig = formRoutingConfig.map((config) => {
        if (
          config.driverId === driverRoutingConfiguration.driverId &&
          config.date === driverRoutingConfiguration.date
        ) {
          if (key === "endLocation") {
            if (
              driver.driver?.homeLocationId ===
              driverRoutingConfiguration.endLocationId
            ) {
              return {
                ...config,
                endLocation: null,
                endLocationId: null,
              };
            }
            return {
              ...config,
              endLocation: driver.driver?.homeLocation,
              endLocationId: driver.driver?.homeLocationId,
            };
          }
          if (
            driver.driver?.homeLocationId ===
            driverRoutingConfiguration.startLocationId
          ) {
            return {
              ...config,
              startLocation: null,
              startLocationId: null,
            };
          }
          return {
            ...config,
            startLocation: driver.driver?.homeLocation,
            useCurrentLocationAtStart: false,
            startLocationId: driver.driver?.homeLocationId,
          };
        }
        return config;
      });
      setFormRoutingConfig(newFormRoutingConfig);
    }
  };

  return (
    <Loading
      loading={driverDefaultRoutingConfigurationsLoading}
      className="min-w-[1000px] min-h-[350px] max-h-[calc(100vh-300px)] overflow-auto"
    >
      <div className="flex items-center gap-4 p-2 rounded-md bg-gray-100 shadow-sm">
        <span className="w-1/6 text-sm font-medium text-gray-600 text-left">
          Driver
        </span>
        <span className="w-1/6 text-sm font-medium text-gray-600 text-left"></span>
        <span className="w-2/6 text-sm font-medium text-gray-600 text-left">
          Start Location & Time
        </span>
        <span className="w-2/6 text-sm font-medium text-gray-600 text-left">
          End Location & Time
        </span>
      </div>
      {formRoutingConfig
        .filter((config) => config.date === selectedStopDate)
        .map((driverRoutingConfiguration) => {
          const driver = getDriver(driverRoutingConfiguration.driverId);
          const showHomeToggle = !!driver?.driver?.homeLocationId;
          const driverError =
            errors[
              `${driverRoutingConfiguration.driverId}-${driverRoutingConfiguration.date}`
            ];
          if (!driver) {
            return null;
          }
          return (
            <div
              key={`${driverRoutingConfiguration.driverId}-${driverRoutingConfiguration.date}`}
              className="grid grid-cols-6 gap-4 p-2 rounded-md bg-white hover:bg-slate-100 transition-all items-center border mb-2"
            >
              <div className="col-span-2 flex justify-between">
                <DriverNameCell
                  name={driver.name}
                  avatarUrl={driver.url}
                  row={driver}
                  driver={driver.driver as Driver | undefined}
                />
                <div className="flex gap-2 items-center">
                  {showHomeToggle && (
                    <div
                      className="cursor-pointer"
                      onClick={() =>
                        handleToggleHomeLocation(
                          driverRoutingConfiguration,
                          driver,
                          "startLocation"
                        )
                      }
                    >
                      <Tooltip
                        content="Use Home Address as Start Location"
                        placement="left"
                      >
                        {driver.driver?.homeLocationId ===
                        driverRoutingConfiguration.startLocationId ? (
                          <HomeIconSolid className="h-6 w-6 text-blue-600" />
                        ) : (
                          <HomeIcon className="h-6 w-6 text-gray-400" />
                        )}
                      </Tooltip>
                    </div>
                  )}
                  <div
                    className="cursor-pointer"
                    onClick={() =>
                      handleChangeCurrentLocationAtStart(
                        driverRoutingConfiguration.driverId,
                        driverRoutingConfiguration.date
                      )
                    }
                  >
                    {driverRoutingConfiguration.useCurrentLocationAtStart ? (
                      <MapPinIconSolid className="h-6 w-6 text-blue-600" />
                    ) : (
                      <MapPinIcon className="h-6 w-6 text-gray-400" />
                    )}
                  </div>
                </div>
              </div>
              <div className="col-span-2 flex items-center justify-center">
                <div className="flex flex-col w-full">
                  <div className="flex w-full items-center justify-center gap-2 flex-col">
                    <div className="w-full">
                      {driverRoutingConfiguration.useCurrentLocationAtStart ? (
                        <p className="py-1.5">Current Location</p>
                      ) : (
                        <AddressInput
                          handleAddressChange={(
                            address:
                              | google.maps.places.AutocompletePrediction
                              | undefined
                          ) => {
                            if (address) {
                              handleAddressChange(
                                address,
                                driverRoutingConfiguration,
                                "startLocation"
                              );
                            } else {
                              handleAddressChange(
                                undefined,
                                driverRoutingConfiguration,
                                "startLocation"
                              );
                            }
                          }}
                          value={
                            driverRoutingConfiguration.startLocation
                              ?.streetAddress
                          }
                          size={"mini"}
                        />
                      )}
                    </div>
                    <TimePicker
                      required
                      error={!!driverError}
                      size="mini"
                      value={
                        driverRoutingConfiguration.startTime
                          ? moment
                              .unix(
                                Number(driverRoutingConfiguration.startTime)
                              )
                              .format("HH:mm")
                          : ""
                      }
                      onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                        handleTimeChange(
                          event.target.value,
                          driverRoutingConfiguration,
                          "startTime"
                        )
                      }
                    />
                    {driverError && (
                      <p className="text-red-500 text-xs mt-1">{driverError}</p>
                    )}
                  </div>
                </div>
              </div>
              <div className="col-span-2 flex items-center justify-center">
                <div className="gap-2 flex flex-col w-full">
                  <AddressInput
                    handleAddressChange={(
                      address:
                        | google.maps.places.AutocompletePrediction
                        | undefined
                    ) => {
                      if (address) {
                        handleAddressChange(
                          address,
                          driverRoutingConfiguration,
                          "endLocation"
                        );
                      } else {
                        handleAddressChange(
                          undefined,
                          driverRoutingConfiguration,
                          "endLocation"
                        );
                      }
                    }}
                    value={
                      driverRoutingConfiguration.endLocation?.streetAddress
                    }
                    size={"mini"}
                  />
                  <TimePicker
                    nullable
                    size="mini"
                    value={
                      driverRoutingConfiguration.endTime
                        ? moment
                            .unix(Number(driverRoutingConfiguration.endTime))
                            .format("HH:mm")
                        : ""
                    }
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                      handleTimeChange(
                        event.target.value,
                        driverRoutingConfiguration,
                        "endTime"
                      )
                    }
                  />
                </div>
                {showHomeToggle && (
                  <div
                    className="cursor-pointer px-2"
                    onClick={() =>
                      handleToggleHomeLocation(
                        driverRoutingConfiguration,
                        driver,
                        "endLocation"
                      )
                    }
                  >
                    <Tooltip content="Use Home Address as End Location">
                      {driver.driver?.homeLocationId ===
                      driverRoutingConfiguration.endLocationId ? (
                        <HomeIconSolid className="h-6 w-6 text-red-600" />
                      ) : (
                        <HomeIcon className="h-6 w-6 text-gray-400" />
                      )}
                    </Tooltip>
                  </div>
                )}
              </div>
            </div>
          );
        })}
    </Loading>
  );
}

export default OptimizationSettingsCreateRoutingJobSlide;
